var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{attrs:{"disabled":_vm.disabled}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-form',{attrs:{"novalidate":""}},[_c('fieldset',{staticClass:"mb-3"},[_c('b-form-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":_vm.validation.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.$v.form.name.$invalid)?_c('b-form-invalid-feedback',[_vm._v(" Informe o nome ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Código","label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","state":_vm.validation.code},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),(_vm.$v.form.code.$invalid)?_c('b-form-invalid-feedback',[_vm._v(" Informe o código ")]):_vm._e()],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Imagem","label-for":"image"}},[_c('ImageInput',{attrs:{"validation":_vm.validation},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}})],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{class:_vm.validationClass.price,attrs:{"label":"Preço Normal","label-for":"price"}},[_c('b-input-group',{attrs:{"prepend":"R$"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:({
                      decimal: ',',
                      thousands: '.',
                      precision: 2
                    }),expression:"{\n                      decimal: ',',\n                      thousands: '.',\n                      precision: 2\n                    }"}],attrs:{"id":"price","state":_vm.validation.price},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),(_vm.$v.form.price.$invalid)?_c('b-form-invalid-feedback',[_vm._v(" O preço deve ser maior que zero ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{class:_vm.validationClass.discountPrice,attrs:{"label":"Preço Promocional","label-for":"discountPrice"}},[_c('b-input-group',{attrs:{"prepend":"R$"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:({
                      decimal: ',',
                      thousands: '.',
                      precision: 2
                    }),expression:"{\n                      decimal: ',',\n                      thousands: '.',\n                      precision: 2\n                    }"}],attrs:{"id":"discountPrice","state":_vm.validation.discountPrice},model:{value:(_vm.form.discountPrice),callback:function ($$v) {_vm.$set(_vm.form, "discountPrice", $$v)},expression:"form.discountPrice"}})],1),(_vm.$v.form.discountPrice.$invalid)?_c('b-form-invalid-feedback',[_vm._v(" O preço promocional deve ser maior que zero ")]):_vm._e()],1)],1)],1),_c('StoresFetcher',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var data = ref.data;
return [_c('fieldset',[_c('div',{staticClass:"flex-1",attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Loja","label-for":"store"}},[_c('BaseSelect',{attrs:{"options":_vm.parseStoreOptions(data, _vm.form.storeId),"reduce":_vm.onReduceStore,"clearable":false,"validation":_vm.$v.form.storeId},model:{value:(_vm.form.storeId),callback:function ($$v) {_vm.$set(_vm.form, "storeId", $$v)},expression:"form.storeId"}}),(_vm.$v.form.storeId.$invalid)?_c('b-form-invalid-feedback',[_vm._v(" Informe a loja ")]):_vm._e()],1)],1)])]}}])}),_c('StatusInput',{attrs:{"validation":_vm.validation.status,"invalid":_vm.$v.form.status.$invalid},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)])],1)],1),_c('div',{staticClass:"mt-3 d-flex gap-1 justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.handleCancel('ProductsIndex')}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.handleSubmit}},[_vm._v(" Salvar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }