const getValidationClass = ({ form, fields }) => {
  return fields.reduce((acc, field) => {
    const validationClass =
      form[field].$dirty && form[field].$invalid ? 'is-invalid' : ''

    return {
      ...acc,
      [field]: validationClass
    }
  }, {})
}

export { getValidationClass }
