<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <StateRenderer v-bind="product">
      <ProductForm v-model="model" :disabled="isLoading" @submit="onSubmit" />
    </StateRenderer>
  </Layout>
</template>

<script>
import { STATUS } from '@/constants'
import { mapGetters } from 'vuex'
import { parseProduct } from '@/helpers/products'
import services from '@/services'
import withAsyncAction from '@/mixins/withAsyncAction'
import Layout from '@/views/Layout'
import ProductForm from '@/components/Products/ProductForm'
import StateRenderer from '@/components/StateRenderer'

export default {
  name: 'ProductRegisterView',
  components: {
    Layout,
    ProductForm,
    StateRenderer
  },
  mixins: [
    withAsyncAction({
      key: 'product',
      fetcher: {
        methodName: 'fetchProductById',
        handler: services.products.fetchProductById
      }
    }),
    withAsyncAction({
      key: 'createProductState',
      fetcher: {
        methodName: 'createProduct',
        handler: services.products.createProduct
      }
    }),
    withAsyncAction({
      key: 'updateProductState',
      fetcher: {
        methodName: 'updateProduct',
        handler: services.products.updateProduct
      }
    })
  ],
  data() {
    return {
      model: {
        storeId: null,
        code: '',
        name: '',
        price: null,
        status: STATUS.ACTIVE.value
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId']),
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Produtos',
        to: { name: 'ProductsIndex' }
      },
      {
        text: 'Cadastro',
        to: { name: 'ProductRegister' },
        active: true
      }
    ],
    isLoading() {
      return this.createProductState.loading || this.updateProductState.loading
    }
  },
  async created() {
    const { id } = this.$route.params

    if (id) {
      this.model = await this.fetchProductById(id)
    } else {
      this.model.storeId = this.currentStoreId
    }
  },
  methods: {
    async onSubmit(product) {
      const parsedProduct = parseProduct(product)

      if (product.id) {
        await this.updateProduct(parsedProduct)
      } else {
        await this.createProduct(parsedProduct)
      }

      this.$root.$toast.success('Produto salvo com sucesso')
      this.$router.push({ name: 'ProductsIndex' })
    }
  }
}
</script>
