import { parseCurrency } from './numbers'

const { HOME_ITEM_TYPES } = require('@/constants')

const parseProducts = (products) =>
  products.map((product) => ({
    ...product,
    type: HOME_ITEM_TYPES.PRODUCT
  }))

const parseProduct = ({ price, discountPrice, ...product }) => ({
  ...product,
  price: parseCurrency(price),
  discountPrice: parseCurrency(discountPrice)
})

export { parseProducts, parseProduct }
